import React, { Component } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';


class AdminOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            address: '',
            city: '',
            municipality: '',
            zipCode: '',
            country: '',
            phone: '', 
            email: '',
            note: '',
            shippingName: '',
            shippingAddress: '',
            shippingCity: '',
            shippingMunicipality: '',
            shippingZipCode: '',
            shippingCountry: '',
            shippingPhone: '',
            shippingEmail: '',
            products: [],
            shippingPrice: '',
            subTotal: '',
            total: ''
        }
    }

    componentDidMount() {
        if(this.props.match.params.id){
            this.getOrder();
        }
    }

    getOrder=()=>{
        Service.get("/orders/single",{
            orderId: this.props.match.params.id
        },(res) => {
            if(res.success){
                this.setState({
                    name: res.order.name,
                    address: res.order.billing_address,
                    city: res.order.city,
                    municipality: res.order.municipality,
                    zipCode: res.order.zip_code,
                    country: res.order.country,
                    phone: res.order.phone,
                    email: res.order.email,
                    note: res.order.note,
                    shippingName: res.order.shipping_name,
                    shippingAddress: res.order.shipping_address,
                    shippingCity: res.order.shipping_city,
                    shippingMunicipality: res.order.shipping_municipality,
                    shippingZipCode: res.order.shipping_zip_code,
                    shippingCountry: res.order.shipping_country,
                    shippingPhone: res.order.shipping_phone,
                    shippingEmail: res.order.shipping_email,
                    products: res.order.products,
                    shippingPrice: res.order.shipping_price,
                    subTotal: res.order.subTotal,
                    total: res.order.total
                },()=>{
                })
            }
        });
    }

   
    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <h1><T text="order"/> #{this.props.match.params.id}</h1>
                    <div className="admin-order">
                        <div className="admin-order-billing">
                            {/* Billing */}
                            <p><T text="cartName"/>: {this.state.name}</p>
                            <p><T text="cartAddress"/>: {this.state.address}</p>
                            <p><T text="cartCity"/>: {this.state.city}</p>
                            <p><T text="cartMunicipality"/>: {this.state.municipality}</p>
                            <p><T text="cartZipCode"/>: {this.state.zipCode}</p>
                            <p><T text="cartCountry"/>: {this.state.country}</p>
                            <p><T text="cartPhone"/>: {this.state.phone}</p>
                            <p><T text="cartEmail"/>: {this.state.email}</p>
                            <p><T text="cartNote"/>: {this.state.note}</p>
                        </div>

                        <div className="admin-order-shipping">
                            {/* Shipping */}
                            <p><T text="cartShippingName"/>: {this.state.shippingName}</p>
                            <p><T text="cartShippingAddress"/>: {this.state.shippingAddress}</p>
                            <p><T text="cartCity"/>: {this.state.shippingCity}</p>
                            <p><T text="cartMunicipality"/>: {this.state.shippingMunicipality}</p>
                            <p><T text="cartZipCode"/>: {this.state.shippingZipCode}</p>
                            <p><T text="cartCountry"/>: {this.state.shippingCountry}</p>
                            <p><T text="cartShippingPhone"/>: {this.state.shippingPhone}</p>
                            <p><T text="cartShippingEmail"/>: {this.state.shippingEmail}</p>
                        </div>
                    </div>
                    
                    <div className="admin-pages-content">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th><T text="name"/></th>
                                    <th><T text="shortDescription"/></th>
                                    <th><T text="price"/></th>
                                    <th><T text="quantity"/></th>
                                    <th><T text="total"/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.products.map(function(item, index){
                                    return(
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.short_description}</td>
                                            <td>{item.price}</td>
                                            <td>{item.quantity}</td>   
                                            <td>{item.total}</td>                                                                                     
                                        </tr>
                                    )},this)
                                }
                            </tbody>
                        </table>
                    </div>
                                
                    <h3><T text="cartSubtotal"/>: {this.state.subTotal} RSD</h3>
                    <h3><T text="cartShipping"/>: {this.state.shippingPrice} RSD</h3>
                    <h3><T text="cartTotal"/>: {this.state.total} RSD</h3>
                    <div className="admin-controls">                   
                    </div>
                </div>
            </div>
        );
    }

}

export default AdminOrder;