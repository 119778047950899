import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import { NotificationManager } from 'react-notifications';


class AdminOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            filter: '',
            perPage: 10,
            page: 1,
            previousPage: 0,
            nextPage: 0,
            totalOrders: ''
        }
    }

    componentDidMount() {
        if(this.props.match.params.page){
            this.setState({page: this.props.match.params.page},()=>{
                this.getOrders();
            });
        }else{
            this.getOrders();
        }
    }

    getOrders=()=>{
        Service.get("/orders/get",{perPage: this.state.perPage, page: this.state.page, filter: this.state.filter},(res) => {
            if(res.success){
                this.setState({orders: res.orders, totalOrders: res.total_orders},()=>{
                    this.handlePages();
                })
            }
        });
    }

    handlePages=()=>{
        this.previousPage = parseInt(this.state.page) - 1;
        this.nextPage = parseInt(this.state.page) + 1;

        let totalPages = Math.ceil(this.state.totalOrders / this.state.perPage);

        if(this.nextPage > totalPages){
            this.nextPage = 1;
        }
        if(this.previousPage <= 0){
            this.previousPage = totalPages;
        }

        this.setState({
            previousPage: this.previousPage,
            nextPage: this.nextPage
        })
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            this.getOrders();
        })
    }

    viewOrder=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');
        window.location.href= '/order/'+orderId;
    }

    deleteOrder=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');
        Service.get("/orders/delete",{
            orderId: orderId
        },(res) => {
            if(res.success){
                this.getOrders();
                NotificationManager.success(<T text="successMessageDeleteOrder"/>)
            }
        });
    }

    updatePerPage=(e)=>{
        this.setState({perPage: e.target.value},()=>{
            this.getOrders();
        })
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <h1><T text="orders"/></h1>
                    {/* Per page */}
                    <div className="per-page-container">
                        <label><T text="perPage"/></label>
                        <select id="perPage" value={this.state.perPage} onChange={(e)=>{this.updatePerPage(e)}}>
                            <option value="10" >10</option>
                            <option value="20" >20</option>
                            <option value="50" >50</option>
                            <option value="100" >100</option>
                        </select>
                    </div>
        
                    <div className='search-container'><input placeholder="search..." type="text" value={this.state.filter} onChange={this.handleFilter} /></div>
                    <div className="admin-pages-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th><T text="order"/></th>
                                        <th><T text="customer"/></th>
                                        <th><T text="shippingAddress"/></th>
                                        <th><T text="total"/></th>
                                        <th><T text="action"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.orders.map(function(item, index){
                                            return(
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>ID: <b><Link to={"/order/" + item.id}>{item.id}</Link></b> <br/>{item.shop}<br/>{item.modified_at}</td>
                                                    <td><b>{item.name}</b><br/>{item.email}<br/>Tel:{item.phone}&nbsp; </td>
                                                    <td><b>{item.shipping_name}</b><br/>{item.shipping_address} <br/>{item.shipping_zip_code} {item.shipping_city}<br/><T text="municipality"/>:&nbsp;{item.shipping_municipality}  </td>
                                                    <td>
                                                        <b>{item.total}</b> RSD
                                                        <input type="image" src="/delete-bin.png" onClick={this.deleteOrder} width="32" data-order-id={item.id}></input>
                                                    </td>
                                                    <td>
                                                        <div className="button" data-order-id={item.id} onClick={this.viewOrder}><T text="details"/></div>
                                                    </td>
                                                </tr>
                                            )
                                        },this)
                                    }
                                </tbody>
                            </table>

                            <div className="pagination">
                                <a href={`/orders/${this.state.previousPage}`} ><div className="button" style={{marginRight: '.5em'}}>-</div></a>
                                <T text="page"/> {this.state.page} / {Math.ceil(this.state.totalOrders / this.state.perPage)}
                                <a href={`/orders/${this.state.nextPage}`} ><div className="button" style={{marginLeft: '.5em'}}>+</div></a>  
                            </div>
                    </div>
                    
                </div>
            </div>
        );
    }

}

export default AdminOrders;