import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';

class AdminPages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: [],
        }
    }

    componentDidMount() {
        this.getPages();
    }

    getPages=()=>{
        Service.get("/store/pages",{},(res) => {
            if(res.success){
                this.setState({
                    pages: res.data
                })
            }
        });
    }

    changePageStatus=(event)=>{
        Service.get("/store/setPageStatus",{
            pageId: event.target.getAttribute('data-page-id'),
            status: event.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                this.getPages();
            }
        });
    }
    
    addPage=()=>{
        window.location.href= '/page';
    }

    editPage=(event)=>{
        let pageId = event.target.getAttribute('data-page-id');
        window.location.href= '/page/'+pageId;
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <div className="button" onClick={this.addPage}><T text="addPage"/></div>

                    <div className="admin-pages-content">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th><T text="slug"/></th>
                                        <th><T text="title"/></th>
                                        <th><T text="status"/></th>
                                        <th><T text="action"/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.pages.map(function(item, index){
                                            return(
                                                <tr key={index}>
                                                    <td>{item.id}</td>
                                                    <td>{item.slug}</td>
                                                    <td>{item.title}</td>
                                                    <td>                                        
                                                        <td><input type="checkbox" data-page-id={item.id} checked={item.status} onChange={this.changePageStatus}/></td>
                                                    </td>
                                                    <td>
                                                        <div className="button" data-page-id={item.id} onClick={this.editPage}><T text="edit"/></div>
                                                    </td>
                                                </tr>
                                            )
                                        },this)
                                    }
                                </tbody>
                            </table>
                    </div>
                    
                </div>
            </div>
        );
    }

}

export default AdminPages;