import Lang from '../components/Lang';
import React, { useContext } from 'react';
import { StoreContext } from '../context/StoreContext';

let languageCodes = {
    en: 0,
    sr: 1
}

export default function T(props){
    const store = useContext(StoreContext);
    return <>{Lang[props.text][languageCodes[store.store.lang_admin]]}</>;
}