import React, { Component } from 'react';
import {Auth} from '../components/Auth';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail:'',
            loginPassword:''
        }
    }

    updateLoginEmail = (event) => {
        this.setState({loginEmail: event.target.value});
    }
    updateLoginPassword = (event) => {
        this.setState({loginPassword: event.target.value});
    }

    login=()=>{
        Auth.email = this.state.loginEmail;
        Auth.authenticateAdmin(this.state.loginPassword, () => {
            this.props.history.push('/products');
        }, ()=>{
            alert('incorrect access data');
        })
    }

  render() {
     
    return (
        <div className="admin admin-login">
            <div className="admin-login-inputs">
                <h1>Admin Login</h1>
                <p>Please enter your email and password to access the admin section:</p>
                <label>Email</label>
                <input value={this.state.loginEmail} onChange={this.updateLoginEmail} />
                {/* <p className="error" style={{display: this.state.loginEmailError}}>* morate da unesete validnu email adresu.</p> */}
            
                <label>Password</label>
                <input type="password" value={this.state.loginPassword} onChange={this.updateLoginPassword} />
                {/* <p className="error" style={{display: this.state.loginPasswordError}}>* morate da unesete validnu email adresu.</p> */}
                <div className="admin-login-button-container">
                    <div className="button" onClick={this.login}>Submit</div>
                </div>
            </div>
        </div>
    );
  }

}

export default Admin;