import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style-mobile.css';
import './selector.css';
// import './header.css';
// import './header-mobile.css';
import './admin.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { StoreProvider } from './context/StoreContext';

const history = createBrowserHistory();
ReactDOM.render(<StoreProvider><Router history={history}><App /></Router></StoreProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
