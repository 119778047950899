import React, { useState, useEffect } from 'react';
import ServiceAdmin from '../components/Service';
import HeaderAdmin from '../components/HeaderAdmin';
import {NotificationManager} from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';

export default function AdminBanners(){
    const [banners, setBanners] = useState([]);
    const [isMedia, setIsMedia] = useState(false);

    useEffect(()=>{
        getBanners();
    },[])

    const getBanners = () => {
        ServiceAdmin.get('/store/getBanners',{},(res)=>{
            setBanners(res.banners);
        })
    }

    const deleteBanner=(e)=>{
        ServiceAdmin.get('/store/deleteBanner',{bannerId: e.target.id},(res)=>{
            getBanners();
        });
    }

    const statusBanner=(e)=>{
        ServiceAdmin.get("/store/showBanner",{
            bannerId: e.target.dataset.id,
            show: e.target.checked ? 1 : 0
        }, (res) => {
            getBanners();
        });
    }

    const setSmallDevice=(e)=>{
        ServiceAdmin.get("/store/setSmallDevice",{
            bannerId: e.target.dataset.id,
            smallDevice: e.target.checked ? 1 : 0
        }, (res) => {
            getBanners();
        });
    }

    const moveBanner=(e)=>{
        ServiceAdmin.get('/store/bannerPosition',{
            bannerId: e.target.dataset.id, 
            direction: e.target.dataset.move, 
            index: e.target.dataset.index
        },(res)=>{
            getBanners();
        });
    }

    const setBanner=(image)=>{
        ServiceAdmin.get("/store/setBanner",{
            banner: image.id
        },(res)=>{
            if(res.success){
                NotificationManager.success(<T text="successMessageSetBanner"/>)
                getBanners();
            }
        });
    }

    return(
        <div className="admin">
            <HeaderAdmin />
            <div className="admin-content">
                <div className="button" onClick={()=>{setIsMedia(true)}}><T text="addBanner"/></div>

                {/* Media */}
                {isMedia === true &&
                    <Media className="media-module" onCancel={()=>{setIsMedia(false)}} onSelect={(image)=>{setBanner(image); setIsMedia(false)}} />
                }

                {/* Banners */}
                {banners.map(function (item, index) {
                    return(
                        <div className="banner-container">
                            <div className={item.visible === 1 ? 'banner-image' : 'banner-image banner-image-hide'} key={index}>
                                <img src={item.image}/><br />
                            </div> 
                               
                            <div className="banner-actions">
                                {/* First banner */}
                                {(index === 0 && banners.length !== index + 1) &&
                                    <div className="banner-controls">
                                        <div className="button" onClick={(e)=>{moveBanner(e)}} data-id={item.id} data-move="1" data-index={index}><T text="moveDown"/></div>
                                        <div className="button" onClick={(e)=>{deleteBanner(e)}} id={item.id}><T text="delete"/></div>
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.visible} onChange={(e)=>{statusBanner(e)}}/>
                                        <label><T text="show"/></label>    
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.small_device} onChange={(e)=>{setSmallDevice(e)}}/>
                                        <label><T text="smallDevice"/></label>                                           
                                    </div>
                                }

                                {/* Middle banners */}
                                {(index !== 0 && banners.length !== index + 1) &&
                                    <div className="banner-controls">
                                        <div className="button" onClick={(e)=>{moveBanner(e)}} data-id={item.id} data-move="-1" data-index={index}><T text="moveUp"/></div>
                                        <div className="button" onClick={(e)=>{moveBanner(e)}} data-id={item.id} data-move="1" data-index={index}><T text="moveDown"/></div>
                                        <div className="button" onClick={(e)=>{deleteBanner(e)}} id={item.id}><T text="delete"/></div>
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.visible} onChange={(e)=>{statusBanner(e)}}/>
                                        <label><T text="show"/></label>    
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.small_device} onChange={(e)=>{setSmallDevice(e)}}/>
                                        <label><T text="smallDevice"/></label> 
                                    </div>
                                }

                                {/* Last banner */}
                                {(index !== 0 && banners.length === index + 1) &&
                                    <div className="banner-controls">
                                        <div className="button" onClick={(e)=>{moveBanner(e)}} data-id={item.id} data-move="-1" data-index={index}><T text="moveUp"/></div>
                                        <div className="button" onClick={(e)=>{deleteBanner(e)}} id={item.id}><T text="delete"/></div>
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.visible} onChange={(e)=>{statusBanner(e)}}/>
                                        <label><T text="show"/></label>    
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.small_device} onChange={(e)=>{setSmallDevice(e)}}/>
                                        <label><T text="smallDevice"/></label> 
                                    </div>
                                }

                                {/* Only one banner */}
                                {(index === 0 && banners.length === index + 1) &&
                                    <div className="controls">
                                        <div className="button" onClick={(e)=>{deleteBanner(e)}} id={item.id}><T text="delete"/></div>
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.visible} onChange={(e)=>{statusBanner(e)}}/>
                                        <label><T text="show"/></label>    
                                        <br />
                                        <input type="checkbox" data-id={item.id} checked={item.small_device} onChange={(e)=>{setSmallDevice(e)}}/>
                                        <label><T text="smallDevice"/></label> 
                                    </div>
                                }
                                
                            </div>
                        </div>
                    );                     
                })} 
            </div>

            <div className="admin-controls"></div>
        </div>
    ); 
}