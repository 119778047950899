import React, { useEffect, useState } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import { NotificationManager } from 'react-notifications';
import T from '../components/Translate';
import Media from '../components/Media';
import AdminQuill from '../components/AdminQuill';

export default function AdminPage(props){
    const { id, handlePageId } = props.match.params;
    const [featuredImage, setFeaturedImage] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [status, setStatus] = useState(1);
    const [statusHeader, setStatusHeader] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isMediaFeaturedImage, setIsMediaFeaturedImage] = useState(false);

    useEffect(()=>{
        if(id){
            getPage();
            getFeaturedImage();
        }
    },[])
    
    const getPage=()=>{
        Service.get("/store/getPage",{pageId: id}, (res) => {
            if(res.success){
                setTitle(res.page.title);
                setSlug(res.page.slug);
                setContent(res.page.content);
                setStatus(res.page.status);
                setStatusHeader(res.page.status_header);
                setIsUpdate(true);
            }
        });
    }

    const createPage=()=>{
        Service.get("/store/addPage",{
            title,
            slug,
            content,
            status,
        },(res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageCreatePage"/>)
                window.location.href= '/page/'+res.lastInsertedId;
            }
        });
    }

    const updatePage=()=>{
        Service.get("/store/updatePage",{
            pageId: id,
            title,
            slug,
            content,
        }, (res) => {
            if(res.success){
                NotificationManager.success(<T text="successMessageUpdatePage"/>)
                getPage();
            }
        });
    }

    const changePageStatus=(e)=>{
        Service.get("/store/setPageStatus",{
            pageId: id,
            status: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getPage();
            }
        });
    }

    const changeStatusHeader=(e)=>{
        Service.get("/store/setPageStatusHeader",{
            pageId: id,
            statusHeader: e.target.checked ? 1 : 0
        }, (res) => {
            if(res.success){
                getPage();
            }
        });
    }

    const removePage=()=>{
        Service.get("/store/removePage",{
            pageId: id
        },(res) => {
            if(res.success){
                window.location.href= '/pages';
            }
        });
    }

    // Featured image
    const getFeaturedImage=()=>{
        Service.get("/store/getPageFeaturedImage",{pageId: id},(res) => {
            if(res.success){
                setFeaturedImage(res.images[0].image);
            }
        });
    }

    const setFeaturedImageFunc=(image)=>{
        Service.get('/store/setPageFeaturedImage',{
            pageId: id,
            featuredImage: image.id
        }, res => {
            if(res.success){
                NotificationManager.success(<T text="successMessageSetPageFeaturedImage"/>)
                getFeaturedImage();
            }
        })
    }

    // Quill content from component
    const setQuillContent=(data)=>{
        setContent(data);
    }

    return(
        <div className="admin">
        <HeaderAdmin />
            <div className="admin-content">
                <h1>{title}</h1>

                <label><T text="title"/></label>
                <input type="text" value={title} onChange={(e)=>{setTitle(e.target.value)}} />

                <label><T text="slug"/></label>
                <input type="text" value={slug} onChange={(e)=>{setSlug(e.target.value.replace(/[^0-9a-zA-Z\/-\s]/g,'').replace(/\s/g, '').replace(/\//g, ''))}} />

                {/* Quill */}
                <AdminQuill content={ content } onChange={setQuillContent}/>

                {!isUpdate &&
                    <div className="button" onClick={()=>{createPage()}}><T text="submit"/></div>
                }

                {isMediaFeaturedImage === true &&
                    <Media className="media-module" onCancel={()=>{setIsMediaFeaturedImage(false)}} onSelect={(image)=>{setFeaturedImageFunc(image); setIsMediaFeaturedImage(false)}} />
                }
            </div>
        

            <div className="admin-controls">
                {isUpdate &&
                    <div>
                        <div className="button" onClick={()=>{updatePage()}}><T text="saveChanges"/></div>

                        <div className="admin-page-active">
                            <input type="checkbox" checked={ status } onChange={(e)=>{changePageStatus(e)}}/>
                            <label><T text="status"/></label>
                        </div>

                        <div className="admin-page-active">
                            <input type="checkbox" checked={statusHeader} onChange={(e)=>{changeStatusHeader(e)}}/>
                            <label><T text="statusHeader"/></label>
                        </div>

                        <h3><T text="featuredImage"/></h3>
                        <div className="admin-product-image">
                            <img className="product-thumbnail" src={featuredImage} onClick={()=>{setIsMediaFeaturedImage(true)}}/>
                            <div className="button" onClick={()=>{setIsMediaFeaturedImage(true)}}><T text="changeImage"/></div>
                        </div>
                        <hr />

                        <div className="admin-page-delete">
                            <div className="button" onClick={()=>{removePage()}}><T text="deletePage"/></div>
                        </div> 
                    </div>
                }
            </div>
        </div>
    )
}