import React, { Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Auth } from '../components/Auth';
import T from './Translate';
class HeaderAdmin extends Component {
    
    logout=()=>{
        Auth.logout(()=>{
            this.props.history.push('/');
        });
    }

    render() {
        return (     
            <div className="admin-sidebar">
                <div className="admin-sidebar-content">

                    <ul>
                        <li className={this.props.location.pathname.split('/')[2] == 'store' ? 'admin-item-active': null}><Link to="/store"><T text="store"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'products' ? 'admin-item-active': null}><Link to="/products"><T text="products"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'media' ? 'admin-item-active': null}><Link to="/media"><T text="media"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'categories' ? 'admin-item-active': null}><Link to="/categories"><T text="categories"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'banners' ? 'admin-item-active': null}><Link to="/banners"><T text="banners"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'pages' ? 'admin-item-active': null}><Link to="/pages"><T text="pages"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'orders' ? 'admin-item-active': null}><Link to="/orders"><T text="orders"/></Link></li>
                        <li className={this.props.location.pathname.split('/')[2] == 'social' ? 'admin-item-active': null}><Link to="/social"><T text="socialNetworks"/></Link></li>
                        {/* <li className={this.props.location.pathname.split('/')[2] == 'themes' ? 'admin-item-active': null}><Link to="/themes">{ta("themes")}</Link></li> */}
                        <li className={this.props.location.pathname.split('/')[2] == 'common-content' ? 'admin-item-active': null}><Link to="/common-content"><T text="privacyPolicy"/> / <T text="headerTermsOfUse"/></Link></li>
                        <li className="admin-logout" onClick={()=>{this.logout()}}>Logout</li>
                    </ul>

                </div>
            </div>
        );
    }
};
export default withRouter(props => <HeaderAdmin {...props}/>);
