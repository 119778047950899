import React, { Component, Fragment } from 'react';
import Service from '../components/ServiceAdmin';
import HeaderAdmin from '../components/HeaderAdmin';
import T from '../components/Translate';
import { NotificationManager } from 'react-notifications';

class AdminSocial extends Component {

    constructor(props) {
        super(props);
        this.state = {
           facebookUrl: '',
           youtubeUrl: '',
           instagramUrl: ''
        }
    }

    componentDidMount(){
        this.getSocialNetworks();
    }

    getSocialNetworks=()=>{
        Service.get("/store/getSocial",{
        }, (res) => {
           if(res.success){
               this.setState({
                   facebookUrl: res.social[0].facebook_url,
                   instagramUrl: res.social[0].instagram_url,
                   youtubeUrl: res.social[0].youtube_url
               })
           }
        });
    }

    handleInput=(event)=>{
        this.setState({[event.target.id]: event.target.value});
    }

    setSocialNetworks=()=>{
        Service.get("/store/setSocial",{
            facebookUrl: this.state.facebookUrl,
            instagramUrl: this.state.instagramUrl,
            youtubeUrl: this.state.youtubeUrl
        }, (res) => {
           if(res.success){
               this.getSocialNetworks();
               NotificationManager.success(<T text="successMessageSocialNetworks"/>)
           }
        });
    }

    render() {
        return(
            <div className="admin">
                <HeaderAdmin />
                <div className="admin-content">
                    <label>Facebook</label>
                    <input type="text" id='facebookUrl' value={this.state.facebookUrl} onChange={this.handleInput} />
                    <label>Youtube</label>
                    <input type="text" id='youtubeUrl' value={this.state.youtubeUrl} onChange={this.handleInput} />
                    <label>Instagram</label>
                    <input type="text" id='instagramUrl' value={this.state.instagramUrl} onChange={this.handleInput} />

                    <div className="button" onClick={this.setSocialNetworks}><T text="submit"/></div>
                </div>

                {/* <div className="admin-controls">
                    <div className="button" onClick={this.setSocialNetworks}>{ta("saveChanges")}</div>
                </div> */}
            </div>
        );
    }

}

export default AdminSocial;